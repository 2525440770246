// @ts-nocheck

import React, { useState } from "react";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import { useAppDispatch, useAppSelector } from "store/useAppSelectorDispatch";
import ExpenseInputView from "./ExpenseInputView";
import type { ChangeEvent } from "react";
import expenseInputsHandler from "../OnInputChangeHandlers/expenseInputsHandler";
import {
    SelectDropDown,
    ModalRow,
    ModalRowHalf,
    Value,
    MuiCalendar,
} from "./../Components";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { expenseObject } from "Components/Registry/Expense";
import { getDefaultName } from "helpers";
import { getRelevantLedgers } from "helpers/ledgers/ledgerDependencies";
import { updateEntityState } from "helpers/updateEntityState";
import useOverrides from "../CustomHooks/useOverrides";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { EventManager } from "Events";
import Swal from "sweetalert2";

const options = [
    "annually",
    "quarterly",
    "monthly",
    "semi-monthly",
    "bi-weekly",
    "weekly",
    "one-time",
];

export default function ExpenseInputExperimental({
    line,
    focus,
    edit,
    editData,
}) {
    const dispatch = useAppDispatch();

    const manager: EventManager = useAppSelector(
        (state) => state?.scenario?.manager
    );

    const entitiesObject = useAppSelector((state) => state?.entities);

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(expenseObject);
            _eventData.name = getDefaultName(expenseObject.name(), propsObject);
        }

        return _eventData;
    });

    const ledgerAccount = getRelevantLedgers([eventData])?.[0];

    const {
        currentEntity, // string - the ID of the current entity
        entitiesMap, // master hashmap containing all entities for that Event
        entityIndex, // Index of the current entity in the array of entityIds
        entityIds, // Array - Entity Ids
        handleClickChangeEntity,
        handleClickDeleteEntity,
        handleClickDuplicateEntity,
        handleClickAddEntityCard,
        setEntitiesMap,
    } = useEntities(entitiesObject, eventData, edit, expenseObject); // "mockEvent" represents the eventObject, which will be provided by the backend later.

    const {
        checkIfFieldIsOverridden,
        isOverrideValid,
        createOverride,
        updateOverride,
        getOverride,
    } = useOverrides(entitiesMap, setEntitiesMap, currentEntity);

    /**
     * Created propsObject because getDefaultName & getPresentableDependenciesOfManyTypes
     * functions expects a props obj with baselineManager and manager.
     * Temporary solution, re-work of this logic is outside of current scope.
     */
    const propsObject = { manager, line, focus };

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    // const onHandleAmortizedDate = (startDate) => {
    //     const newEntitiesMap = { ...entitiesMap };

    //     const currentEntityObject = {
    //         ...(newEntitiesMap[currentEntity] || {}),
    //     };

    //     currentEntityObject.startDate = startDate;

    //     const data = { ...(currentEntityObject?.data || {}) };

    //     data.amortizedEnd = calculateAmortizedEndDate(
    //         startDate,
    //         newEntitiesMap[currentEntity].data.amortizationPeriod,
    //         newEntitiesMap[currentEntity].cadence,
    //         entitiesMap,
    //         currentEntity
    //     );

    //     currentEntityObject.data = data;
    //     newEntitiesMap[currentEntity] = currentEntityObject;

    //     setEntitiesMap(newEntitiesMap);
    // };

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id:
            | "value"
            | "amortizedValue"
            | "url"
            | "entityName"
            | "cadence"
            | "rating"
            | "amortizationPeriod"
            | "inflationRate",
        star: number
    ) => {
        const value = e.target.value;
        const newEntitiesMap = expenseInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleDateSelection = (id, value) => {
        const star = 0;
        const newEntitiesMap = expenseInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    const updateAccount = (accountType, id) => {
        const value = accountType;
        const star = 0;
        const newEntitiesMap = expenseInputsHandler(
            "",
            id,
            star,
            entitiesMap,
            currentEntity,
            value
        );
        setEntitiesMap(newEntitiesMap);
    };

    const onHandleSubmit = () => {
        eventData.mostRecentEntity = entityIndex ?? 0;
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };

    const toggleInflation = () => {
        const newEntitiesMap = { ...entitiesMap };

        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };

        const data = { ...(currentEntityObject?.data || {}) };

        data.applyInflation = !data.applyInflation;

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const removeOverride = (field: string) => {
        if (entitiesMap[currentEntity]?.data?.modsCreated) {
            const index = entitiesMap[
                currentEntity
            ]?.data?.modsCreated.findIndex((modifier) => {
                return (
                    modifier.action === "override" &&
                    modifier.keyToModify === field
                );
            });

            if (index >= 0)
                entitiesMap[currentEntity]?.data?.modsCreated.splice(index, 1);
        }
    };

    const handleChangeExpenseType = async (e) => {
        const newEntitiesMap = { ...entitiesMap };

        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };

        const data = { ...(currentEntityObject?.data || {}) };

        const newType = e.target.id;
        let newValue;
        if (newType === "ongoing") {
            newValue = Number(data.cost);
        } else {
            if (getOverride("value")) {
                const result = await Swal.fire({
                    title: "Amortization will clear existing overrides.",
                    showCancelButton: true,
                    showConfirmButton: true,
                    cancelButtonText: "Cancel",
                    confirmButtonText: "Confirm",
                });
                if (!result?.value) {
                    return;
                }
                removeOverride("value");
            }

            const paymentAmount = Number(data.cost) / data.numPayments;
            newValue = paymentAmount.toFixed(2);
        }

        data.expenseType = newType;
        data.value = newValue;
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const getOngoingExpensePanel = () => {
        const { cadence } = entitiesMap[currentEntity];
        const { cost } = entitiesMap[currentEntity].data;

        return (
            <>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <SelectDropDown
                            onChangeInput={handleOnChange}
                            value={cadence}
                            options={options}
                            className="select-dropdown"
                            id="cadence"
                            label="Select Payment Frequency"
                            required={true}
                            disabled={getOverride("value") !== undefined}
                        />
                    </ModalRowHalf>
                    <ModalRowHalf>
                        <Value
                            label="Cost"
                            onChangeInput={handleOnChange}
                            value={cost}
                            id="value"
                            override={isOverrideValid(
                                entitiesMap,
                                currentEntity
                            )}
                            entityData={entitiesMap?.[currentEntity]}
                            overridden={checkIfFieldIsOverridden("value")}
                            ledgerAccount={ledgerAccount?.Name ?? ""}
                            unit="$"
                            required={true}
                            createOverride={createOverride}
                            updateOverride={updateOverride}
                        />
                    </ModalRowHalf>
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <MuiCalendar
                            id="startDate"
                            value={
                                entitiesMap?.[currentEntity]?.startDate === ""
                                    ? null
                                    : entitiesMap?.[currentEntity]?.startDate
                            }
                            required={true}
                            onChangeInput={handleDateSelection}
                            label="Start Date"
                        />
                    </ModalRowHalf>
                    {entitiesMap?.[currentEntity]?.cadence !== "one-time" && (
                        <ModalRowHalf>
                            <MuiCalendar
                                id="endDate"
                                value={
                                    entitiesMap?.[currentEntity]?.endDate === ""
                                        ? null
                                        : entitiesMap?.[currentEntity]?.endDate
                                }
                                onChangeInput={handleDateSelection}
                                label="End Date"
                                helperText="An end date is optional"
                            />
                        </ModalRowHalf>
                    )}
                </ModalRow>
            </>
        );
    };

    const handleEntityStateChange = (id, _value) => {
        const newEntitiesMap = updateEntityState(
            entitiesMap,
            currentEntity,
            id
        );
        setEntitiesMap(newEntitiesMap);
    };
    const passedCheck =
        !!eventData.name && expenseObject.checkInput(entitiesMap);

    return (
        <ExpenseInputView
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            entityIndex={entityIndex}
            handleClickAddEntityCard={handleClickAddEntityCard}
            handleClickChangeEntity={handleClickChangeEntity}
            handleClickDeleteEntity={handleClickDeleteEntity}
            handleClickDuplicateEntity={handleClickDuplicateEntity}
            entitiesLength={entityIds.length}
            onChangeNameDescription={onChangeNameDescription}
            passedCheck={passedCheck}
            onHandleSubmit={onHandleSubmit}
            handleDateSelection={handleDateSelection}
            getOngoingExpensePanel={getOngoingExpensePanel}
            handleChangeExpenseType={handleChangeExpenseType}
            handleOnChange={handleOnChange}
            toggleInflation={toggleInflation}
            isOngoing={
                entitiesMap?.[currentEntity]?.data?.expenseType === "ongoing"
            }
            edit={edit}
            eventData={eventData}
            handleEntityStateChange={handleEntityStateChange}
            updateAccount={updateAccount}
        />
    );
}

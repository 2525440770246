import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/useAppSelectorDispatch";
import DebitCreditInputView from "./DebitCreditInputView";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import { getDefaultName } from "../../../helpers";
import { debitCreditObject } from "Components/Registry/Debit Credit";
import type { ChangeEvent } from "react";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { debitCreditInputsHandler } from "../OnInputChangeHandlers/debitCreditInputsHandler";
import { updateEntityState } from "helpers/updateEntityState";
import useOverrides from "../CustomHooks/useOverrides";
import { addNewEvent, updateEvent } from "actions/eventHelpers";

export default function DebitCreditInputExperimental({
    line,
    focus,
    edit,
    editData,
}) {
    const dispatch = useAppDispatch();

    const baselineManager = useAppSelector(
        (state) => state?.scenario?.baselineManager
    );
    const manager = useAppSelector((state) => state?.scenario?.manager);
    const entitiesObject = useAppSelector((state) => state?.entities);

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(debitCreditObject);
            _eventData.name = getDefaultName(
                debitCreditObject.name(),
                propsObject
            );
        }

        return _eventData;
    });

    const {
        currentEntity,
        entitiesMap,
        entityIndex,
        entityIds,
        handleClickChangeEntity,
        handleClickDeleteEntity,
        handleClickDuplicateEntity,
        handleClickAddEntityCard,
        setEntitiesMap,
        updateInflation,
    } = useEntities(entitiesObject, eventData, edit, debitCreditObject); // "mockEvent" represents the eventObject, which will be provided by the backend later.

    const {
        checkIfFieldIsOverridden,
        isOverrideValid,
        createOverride,
        updateOverride,
        getOverride,
    } = useOverrides(entitiesMap, setEntitiesMap, currentEntity)

    const ledgerAccount: string =
        entitiesMap?.[currentEntity]?.data?.accountName;

    /**
     * Created propsObject because getDefaultName & getPresentableDependenciesOfManyTypes
     * functions expects a props obj with baselineManager and manager.
     * Temporary solution, re-work of this logic is outside of current scope.
     */
    const propsObject = { manager, line, focus };

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id:
            | "value"
            | "entityName"
            | "cadence"
            | "rating"
            | "debitOrCreditType"
            | "accountName",
        star: number
    ) => {
        const value = e.target.value;
        const newEntitiesMap = debitCreditInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleDateSelection = (id, value) => {
        const star = 0;
        const newEntitiesMap = debitCreditInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    }

    const handleEntityStateChange = (id, _value) => {
        const newEntitiesMap = updateEntityState(
            entitiesMap,
            currentEntity,
            id
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleChangeDebitCredit = (e: ChangeEvent<HTMLInputElement>) => {
        const newType = e.target.id;
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        data.debitOrCredit = newType;
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const handleChangeAmortizedOngoing = (e: ChangeEvent<HTMLInputElement>) => {
        const newType = e.target.id;
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        data.amortizedOrOngoing = newType;
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    // const onHandleAmortizedDate = (startDate) => {
    //     const newEntitiesMap = { ...entitiesMap };

    //     const currentEntityObject = {
    //         ...(newEntitiesMap[currentEntity] || {}),
    //     };

    //     currentEntityObject.startDate = startDate;

    //     const data = { ...(currentEntityObject?.data || {}) };

    //     data.amortizedEnd = calculateAmortizedEndDate(
    //         startDate,
    //         newEntitiesMap[currentEntity].data.amortizationPeriod,
    //         newEntitiesMap[currentEntity].cadence,
    //         entitiesMap,
    //         currentEntity
    //     );

    //     currentEntityObject.data = data;
    //     newEntitiesMap[currentEntity] = currentEntityObject;

    //     setEntitiesMap(newEntitiesMap);
    // };

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const newEntitiesMap = { ...prevEntitiesMap };
            return newEntitiesMap;
        });
        // setEntitiesMap and baselineManager should never change so only if currentEntity changes, does this useEffect get run;
    }, [currentEntity, baselineManager, setEntitiesMap]);

    const onHandleSubmit = () => {
        eventData.mostRecentEntity = entityIndex ?? 0;
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };

    const passedCheck =
        !!eventData.name && debitCreditObject.checkInput(entitiesMap);

    return (
        <DebitCreditInputView
            setEntitiesMap={setEntitiesMap}
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            entityIndex={entityIndex}
            handleClickAddEntityCard={handleClickAddEntityCard}
            handleClickChangeEntity={handleClickChangeEntity}
            handleClickDeleteEntity={handleClickDeleteEntity}
            handleClickDuplicateEntity={handleClickDuplicateEntity}
            eventData={eventData}
            entitiesLength={entityIds.length}
            onChangeNameDescription={onChangeNameDescription}
            handleChangeDebitCredit={handleChangeDebitCredit}
            handleChangeAmortizedOngoing={handleChangeAmortizedOngoing}
            handleDateSelection={handleDateSelection}
            updateInflation={updateInflation}
            passedCheck={passedCheck}
            onHandleSubmit={onHandleSubmit}
            edit={edit}
            handleOnChange={handleOnChange}
            handleEntityStateChange={handleEntityStateChange}
            ledgerAccount={ledgerAccount}
            checkIfFieldIsOverridden={checkIfFieldIsOverridden}
            isOverrideValid={isOverrideValid}
            createOverride={createOverride}
            getOverride={getOverride}
            updateOverride={updateOverride}
        />
    );
}

// Default Imports
import React from "react";

// Import Rental Income Card and Rental Income Input
import DebitCreditCard from "../Card/debitCreditCard";

// Import Connection Image
import debitCreditConnectionImage from "Assets/_optionDescriptionIcons/Debit Credit white.jpg";

// Import Coloured PNGs
import debitCreditBlack from "../../Assets/_eventNodeTypes/debit-credit-black.svg";
import debitCreditBlue from "../../Assets/_eventNodeTypes/income-blue.png";
import debitCreditWhite from "../../Assets/_eventNodeTypes/income-white.png";
import debitCreditGrey from "../../Assets/_eventNodeTypes/income-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/whatifiBasics2x.png";
import DebitCreditInputExperimental from "Components/InputContainer/DebitCreditInputExperimental/DebitCreditInputExperimental";

import { debitCreditInputsHandler } from "Components/InputContainer/OnInputChangeHandlers/debitCreditInputsHandler";

// Notes
// To call an object with no parameters, can simply use
//  - debitCreditObject.constant()

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - debitCreditObject.checkInput.call(debitCreditObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for devbit credit
export const debitCreditObject = {
    constant: function () {
        return debitCredit_Constant();
    },

    name: function () {
        return "Debit Credit";
    },

    checkInput: function (state) {
        return debitCredit_CheckInput(state);
    },

    svg: function () {
        return debitCredit_Svg();
    },

    eventNodeMenu: function (focus) {
        return debitCredit_EventNodeMenu(focus);
    },

    indexText: function () {
        return debitCredit_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return debitCredit_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return debitCredit_OptionDescription();
    },

    colouredPngs: function () {
        return debitCredit_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return debitCredit_SideModalImage();
    },

    inputsHandler: function (
        value,
        id,
        star,
        entitiesMap,
        currentEntity,
        account
    ) {
        return debitCreditInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity,
            account
        );
    },

    generateDisplayFieldData: function () {
        return DebitCredit_GenerateDisplayFieldData();
    },
    // Add Front/Back end data exports
};

// Helper functions for Rental Income

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const debitCredit_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.cadence) return false;
        if (!entity.startDate) return false;
        if (!entity.name) return false;
        if (!entity.data.amount) return false;
        if (!entity.data.accountName) return false;
        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        passedCheck = checkEntity(entity);
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const debitCredit_Constant = () => {
    return "a187c064-beec-43dc-9599-16978b783e31";
};

// EFFECTS: Returns svg for event
const debitCredit_Svg = () => {
    return debitCreditBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const debitCredit_EventNodeMenu = (focus) => {
    return <DebitCreditCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const debitCredit_IndexText = () => {
    return "Select an input field to learn more about your debit credit calculations.";
};

// EFFECTS: Returns all icons for event ??
const debitCredit_Icons = () => {
    let pngs = new Map([
        ["black", debitCreditBlack],
        ["blue", debitCreditBlue],
        ["white", debitCreditWhite],
        ["grey", debitCreditGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const debitCredit_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const debitCredit_InputComponentInfoPassing = (minProps) => {
    return <DebitCreditInputExperimental {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, templates for event
const debitCredit_OptionDescription = () => {
    const tags = ["Option"];
    const type = debitCreditObject.name();
    const description =
        "The Debit/Credit Event is a foundational Event that can be used to model most generic transactions. When in doubt, use this Event. The Debit/Credit Event is the easiest way to model a transaction and control which Accounts are impacted by that calculation. Calculations can be one-time, recurring, or amortized over a fixed period of time.";
    const usedForText =
        "Use this Event for any basic contra-account style transaction where advanced calculations are not required.";
    const connection = "";
    const connectionImage = debitCreditConnectionImage;
    const learnMoreLink =
        "https://bench.co/blog/bookkeeping/debits-credits/#:~:text=need%20to%20know.-,What%20are%20debits%20and%20credits%3F,entry%20method%20for%20their%20accounting.";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const debitCredit_SideModalImage = () => {
    return sideImage;
};

const DebitCredit_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {
        name: {
            displayName: "Name",
            editable: true,
            rowDrag: true,
            checkboxSelection: true,
        },
        startDate: {
            displayName: "Start Date",
            editable: false,
            cellRenderer: "dateSelectorCellRenderer",
        },
        endDate: {
            displayName: "End Date",
            editable: false,
            cellRenderer: "dateSelectorCellRenderer",
        },
        cadence: {
            displayName: "Frequency",
            editable: false,
            cellRenderer: "dropDownCellRenderer",
            cellRendererDropDownOptions: [
                "annually",
                "quarterly",
                "monthly",
                "semi-monthly",
                "bi-weekly",
                "weekly",
                "daily",
                "one-time",
            ],
        },
        bypassState: {
            displayName: "Bypassed",
            editable: false,
            cellRenderer: "checkBoxCellRenderer",
        },
    };

    const entityDataFieldsMap = {
        debitOrCredit: {
            displayName: "Type",
            editable: false,
            cellRenderer: "dropDownCellRenderer",
            cellRendererDropDownOptions: ["debit", "credit"],
        },
        amortizedOrOngoing: {
            displayName: "Expense Type",
            editable: false,
            conditionalTarget: true,
            cellRenderer: "dropDownCellRenderer",
            cellRendererDropDownOptions: ["amortized", "ongoing"],
        },
        value: {
            displayName: "Amount",
            formatting: {
                type: "numerical",
                displaySign: true,
                sign: "$",
                signLocation: "before",
                incudeSignSpace: false,
            },
            editable: false,
            conditionalFieldName: "amortizedOrOngoing",
            conditionalFieldConditionValue: "ongoing",
        },
        amount: {
            hide: true,
        },
        accountName: {
            displayName: "Account Name",
            editable: false,
            cellRenderer: "accountWaterfallCellRenderer",
        },
        accountIds: {
            hide: true,
        },
        contraAccountName: {
            displayName: "Contra Account Name",
            editable: false,
            cellRenderer: "accountWaterfallCellRenderer",
        },
        contraAccountIds: {
            hide: true,
        },
        amortizedValue: {
            displayName: "Amortization Value",
            formatting: {
                type: "numerical",
                displaySign: true,
                sign: "$",
                signLocation: "before",
                incudeSignSpace: false,
            },
            editable: false,
            conditionalFieldName: "amortizedOrOngoing",
            conditionalFieldConditionValue: "amortized",
        },
        amortizationPeriod: {
            displayName: "Amortization Period",
            formatting: {
                type: "numerical",
                displaySign: false,
                sign: "",
                signLocation: "",
                incudeSignSpace: false,
            },
            editable: false,
            conditionalFieldName: "amortizedOrOngoing",
            conditionalFieldConditionValue: "amortized",
        },
    };

    return { entityFieldsMap, entityDataFieldsMap };
};

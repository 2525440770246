import { contractObject } from "Components/Registry/Contract";
import { customerObject } from "Components/Registry/Customer";
import { customer2Object } from "Components/Registry/Customer2";
import { customerChurnObject } from "Components/Registry/CustomerChurn";
import { customerGrowthObject } from "Components/Registry/CustomerGrowth";
import { customerGrowth2Object } from "Components/Registry/CustomerGrowth2";
import { debtObject } from "Components/Registry/Debt";
import { loanObject } from "Components/Registry/Loan";
import { modifier2Object } from "Components/Registry/Modifier2";
import { EntitySchema } from "reducers/typesSchema/entitiesSchema";
import { stringsToDates } from "./stringToDateHelpers";

/**
 * This function takes in an entity and the scenario start date. If the entity start date is
 * before the scenario start date, and the entity is of a type that we DO NOT want to calculate
 * past ledgers for then we return true. False otherwise.
 */
export const isPastAndDoNotCalculate = (
    entity: EntitySchema,
    scenarioStartDate: string
) => {
    // Convert strings to date objects so we can compare them
    const [entityDate, scenarioDate] = stringsToDates(
        entity.startDate,
        scenarioStartDate
    );
    // Check if the entity date is in the past and that we do NOT want to calculate the entity
    if (entityDate < scenarioDate && !doCalculate.has(entity.type)) {
        return true;
    }
    return false;
};

// A set that contains all of the events that we DO want to calculate past ledgers for.
const doCalculate: Set<string> = new Set([
    customerObject.constant(),
    customerGrowthObject.constant(),
    customerChurnObject.constant(),
    loanObject.constant(),
    debtObject.constant(),
    contractObject.constant(),
    modifier2Object.constant(),
    customer2Object.constant(),
    customerGrowth2Object.constant(), // the startDate field here is actually empty, but I'm adding it here for robustness :)
]);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from "react";
import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";

import {
    Description,
    InputButtons,
    EventEntityWrapper,
    EventDetails,
    EntityCard,
    EventHeader,
    ModalRow,
    InflationInput,
    ToggledContainer,
    ToggledContainerHalf,
    EntityName,
} from "../Components";
import { expenseObject } from "../../Registry/Expense";
import { InputDropDownButton } from "Components/AccountSelectionWaterfall/buttons/InputDropDownButton";
import AmortizedExpensePanel from "Components/AmortizedExpensePanel";

export default function ExpenseInputView({
    entitiesMap,
    currentEntity,
    entityIndex,
    entitiesLength,
    handleClickAddEntityCard,
    updateAccount,
    handleClickChangeEntity,
    handleDateSelection,
    handleClickDeleteEntity,
    handleClickDuplicateEntity,
    onChangeNameDescription,
    passedCheck,
    onHandleSubmit,
    getOngoingExpensePanel,
    handleChangeExpenseType,
    handleOnChange,
    toggleInflation,
    isOngoing,
    edit,
    eventData,
    handleEntityStateChange,
}) {
    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData?.name}
                    eventType={expenseObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={expenseObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData?.description}
                        onChangeInput={onChangeNameDescription}
                    />
                </ModalRow>
            </EventDetails>
            <EntityCard
                handleClickChangeEntity={handleClickChangeEntity}
                handleClickDeleteEntity={handleClickDeleteEntity}
                handleClickDuplicateEntity={handleClickDuplicateEntity}
                handleClickAddEntityCard={() =>
                    handleClickAddEntityCard(
                        EntityStructureData?.[expenseObject.constant()]
                    )
                }
                entityIndex={entityIndex}
                entitiesLength={entitiesLength}
                passedCheck={passedCheck}
            >
                <ModalRow>
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                        entityData={entitiesMap?.[currentEntity]}
                        handleEntityStateChange={handleEntityStateChange}
                        required={true}
                    />
                </ModalRow>
                <ToggledContainer>
                    <ToggledContainerHalf
                        id="ongoing"
                        isSelected={isOngoing}
                        onClick={handleChangeExpenseType}
                        title="Ongoing Expense"
                    />
                    <ToggledContainerHalf
                        id="amortized"
                        isSelected={!isOngoing}
                        onClick={handleChangeExpenseType}
                        title="Amortized Expense"
                    />
                </ToggledContainer>
                {isOngoing ? (
                    getOngoingExpensePanel()
                ) : (
                    <AmortizedExpensePanel
                        entitiesMap={entitiesMap}
                        currentEntity={currentEntity}
                        handleOnChange={handleOnChange}
                        handleDateSelection={handleDateSelection}
                        unitless={false}
                        increase={false}
                        amortizedValue={entitiesMap?.[currentEntity]?.data.cost}
                        numPayments={
                            entitiesMap?.[currentEntity]?.data.numPayments
                        }
                        amortizationPeriod={
                            entitiesMap?.[currentEntity]?.data
                                .amortizationPeriod
                        }
                        cadence={entitiesMap?.[currentEntity]?.cadence}
                        startDate={entitiesMap?.[currentEntity]?.startDate}
                        endDate={
                            entitiesMap?.[currentEntity]?.data.amortizedEnd
                        }
                    />
                )}
                <ModalRow>
                    <InputDropDownButton
                        callback={updateAccount}
                        initialValue={
                            entitiesMap?.[currentEntity]?.data?.accountName ??
                            ""
                        }
                        topLevelFilters={{
                            "7faf0285-78ca-411b-b875-d900929d7c94": true,
                        }}
                        required={true}
                    />
                </ModalRow>
                <ModalRow>
                    <InputDropDownButton
                        callback={updateAccount}
                        initialValue={
                            entitiesMap?.[currentEntity]?.data
                                ?.contraAccountName
                        }
                        contraAccount={true}
                        // this is the top level account which is at position 0
                        accountSelectedForContra={
                            entitiesMap?.[currentEntity]?.data?.accountIds
                                ?.length
                                ? entitiesMap?.[currentEntity]?.data
                                      .accountIds[0]
                                : ""
                        }
                    />
                </ModalRow>
                {isOngoing && (
                    <ModalRow>
                        <InflationInput
                            applyInflation={
                                entitiesMap?.[currentEntity]?.data
                                    ?.applyInflation
                            }
                            inflationRate={
                                entitiesMap?.[currentEntity]?.data
                                    ?.inflationRate
                            }
                            onChangeInput={handleOnChange}
                            toggleInflation={toggleInflation}
                        />
                    </ModalRow>
                )}
            </EntityCard>
            <InputButtons
                edit={edit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
                updateValues={onHandleSubmit}
            />
        </EventEntityWrapper>
    );
}
